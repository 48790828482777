* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
        font-family: 'Montserrat',sans-serif;
        line-height: 2em;
        color: #333;
        font-weight: 400;
        letter-spacing: 0.025em;
    }
    

img {
    width: 100%;
    height: auto;
    display: block;
}

h1 {
    color: #39483a;
    padding: 2rem;
}

span {
    font-weight: normal;
}

/* Nav */
a {
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    padding: .5rem;
    color: #fff;
    border-radius: 15px;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

button {
    padding: .5rem 1rem;
    border-radius: 15px;
    margin-top: 1rem;
    margin-right: 10px;
    border: none;
    background-color: #A3D821;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

button:hover {
    background-color: #076325 ;
    cursor: pointer;
}

input {
    margin-right: 10px;
    margin-bottom: 20px;
    padding: .5rem;
    border-radius: 15px;
    border: none;
    background-color: #c9d4c9;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    outline: 1px solid #39483a;
}

label {
    margin-right: 10px;
}

.navWrapper {
    width: 100%;
    min-height: 100px;
    background-color: #FF5733;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
}

.navWrapper img, footer img {
    max-width: 16em;
    cursor: pointer;
}

.navWrapper img {
    margin-bottom: 25px;
}

.navWrapper:hover {
    background-color: none;
}

.navWrapper a {
    color: #fff;
}

.navigation a.active_link, .ancor:hover{
    color: #FF5733;
    background-color: #fff;
    border-radius: 15px;
}

.navigation a {
    margin: 0px 10px;
}

.navigation a:last-child {
    margin-right: 0;

}

.bonsaiCard img {
    max-width: 700px;
    max-height: 700px;
}

.fa-cart-shopping {
    font-size: 1.5rem;
}

/* Admin */
.admin h4{
    margin-left: 2rem;
    margin-top: 2rem;
}

/* Home */

.overlay-container {
    display: flex;
    position: relative;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;

  }

.logo-home {
    position: absolute;
    z-index: 10;
    max-height: 400px;
    margin-bottom: 2rem;

}

.background {
    background-color: #0a0a0a;
    opacity: 0.8;
    width: 100%;
    height: 500px;
    position: absolute;
}

.cart-image {
    height: 500px;
}

.overlay-container h1 {
    position: absolute;
    bottom: 0;
    color: #fff;

}

.sections {
    padding: 2rem;
    display: grid;
    grid-template-areas: 
    "meat produce deli";
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 2rem;
    cursor: pointer;
}

.sections img {
    height: 200px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 20px;
}

.meat-section {
    grid-area: meat;
    position: relative;
}

.produce-section {
    grid-area: produce;
    position: relative;
}

.deli-section {
    grid-area: deli;
    position: relative;
}


.info {
    color: #fff;
    font-size: 3rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    top: 20%;
    left: 10%;
    transition: top 0.3s ease; /* Add transition property for smooth effect */
}
.fa-solids{
    margin-bottom: 1.5rem;
}

.info:hover {
    top: 15%;
}

.info h6 {
    margin-top: 5px;
    font-size: 1rem;
}

.carousel h3 {
    padding-left: 2rem;

}

/* grocery-collection */

.grocery-collection {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.grocery-collection h1{
    padding: 2rem;
}

.grocery-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}
/* GroceryCard */

.grocery-card{
    margin: 1rem;
}

.grocery-card h3 {
    font-size: .8rem;
}

.grocery-wrapper .details{
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.grocery-wrapper .details div{
    display: flex;
}

.details p{
    font-style: italic;
    margin-right: .5rem;
    color: #333;
    font-size: 10px;
}

.grocery-wrapper .details h4{
    color: #333;
    font-size: 12px;
}

.image-container {
    height: 200px;
    width: 200px;
    border-radius: 10px;
}

.grocery-img{
    /* Optionally, you can add additional styles to the image */
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out;
    object-fit: cover; /* To make sure the image covers the container without stretching */
  }

  .grocery-img:hover  {
    transform: scale(1.2);
  }

  .grocery-wrapper h3 {
    margin-top: 3rem;
  }
  /* grocery-details */

  .grocerydetails {
    padding: 2rem;
  }
  .grocery-details img {
    max-width: 700px;
    height: auto;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .edit-btn:hover {
    background-color: orange;
  }

  .delete-btn:hover, .cancel-btn:hover
  {
    background-color: red;
  }

/* grocery form */

.grocery-form, .orders-container, .cart-container {
    height: 100vh;
    padding: 2rem;
    display: flex;
    justify-content: center;
}

.grocery-form form {
    max-width: 600px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 1rem;
    height: 400px;
    border-radius: 25px;

}
/* editgrocery */
.edit-grocery {
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

.edit-grocery form, .grocery-form form, .authentication form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.edit-grocery form input, .grocery-form form input, .authentication form input {
    width: 500px;
}

.edit-grocery .cancel-btn {
    width: 110px;
    align-self: center;
}

.submit-btn:hover, .tag-btn:hover, .add-to-garden button:hover {
    background-color: green;
}

/* AddToGarden */
.add-grocery {
    padding: 2rem;
    height: 100vh;
}

.add-to-garden span {
    margin-right: 10px;
}

/* Authentication */

.authentication {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.authentication form {
    margin-top: 2rem;
    background-color: #c9d4c9;
    padding: 2rem;
    border-radius: 20px;
}

.authentication form input {
    background-color: #fff;
}

.authentication .sign-up {
    display: flex;
    margin-top: 5px;
}

.authentication .sign-up button {
    margin-top: 0;
    background-color: transparent;
    color: #39483a;
    box-shadow: none;
    text-decoration: underline;
    font-size: 1rem;
    cursor: pointer;
}

/* Order */

.orders-container{
    display: inline-block;
    height: 100vh;
}

.cart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cart-container button {
    margin-bottom: 1rem;
}

.cart-container ul, .cart-container li{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.cart-container img {
    max-width: 200px;
}

/* Checkout */

.checkout {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    max-width: 500px;
    position: relative;
}

.checkout-wrapper {
    display: flex;
    justify-content: center;
    height: 100vh;
}

.complete {
    position: absolute;
    left: 70px;
}

.back-cart {
    margin-top: 4rem;
}


/* Footer */
footer {
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FF5733;
    color: white;
    margin-top: auto; /* Pushes the footer to the bottom */
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer; /* Adding cursor style to indicate it's clickable */
}

.logo-div {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.logo-div img {
    margin-right: 10px;
}

.social-media {
    display: flex;
    align-items: center;
}

.social-media h3 {
    margin-right: 10px;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

ul li {
    margin-right: 10px;
}

/* Adding a hover effect to the NavLink */
.logo-div:hover,
.social-media:hover {
    transition: background-color 0.3s ease-in-out;
}






